import React from 'react'
import Hero from '../components/Home/Hero'
import HeroShop from '../components/Home/Shop'
import HeroChoose from '../components/Home/Choose'
import HeroTagLine from '../components/Home/TagLine'
import HeroCustom from '../components/Home/Custom'
import HeroHow from '../components/Home/How'
import NavBar from '../components/NavBar/NavBar'
import Footer from '../components/Footer/Footer'
import { useNavigate } from 'react-router-dom'
import { Fade } from "react-awesome-reveal";


const Home = () => {
  const navigate = useNavigate();

  return (
    <div>
      <NavBar />
      <Fade>
        <Hero />
        <HeroShop />
        <HeroChoose />
        <HeroTagLine />
        <HeroCustom />
        <HeroHow />
        <Footer />
      </Fade>
    </div>
  )
}

export default Home

