// pages/NotFound.js
import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Error from "../img/404.png"
import { Fade } from "react-awesome-reveal";

const NotFound = () => {
    return (
        <Fade>
            <div className="d-flex align-items-center justify-content-center vh-100">
                <div className="text-center row">
                    <div className="col-md-6">
                        <img src={Error} alt="" className="img-fluid" />
                    </div>
                    <div className="col-md-6 mt-5">
                        <p className="fs-3"> <span className="text-danger">Opps!</span> Page not found.</p>
                        <p className="lead">
                            The page you’re looking for doesn’t exist.
                        </p>
                        <Link to="/" className="custom-button">Go Home</Link>
                    </div>
                </div>
            </div>
        </Fade>
    );
};

export default NotFound;
