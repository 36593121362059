import React from 'react';
import { MDBModalBody } from 'mdb-react-ui-kit';
import Payment from './Payment'; 
import Modal from 'react-modal';

Modal.setAppElement('#root'); // Specify the root element of your app

const customModalStyle = {
  content: {
    maxWidth: '100vh', // Set the maximum width of the modal
    margin: 'auto',    // Center the modal horizontally
    maxHeight: '50vh', // Set the maximum height of the modal
    overflowY: 'auto', // Add vertical scrollbar if needed
  }
};

const CheckoutModal = ({ isOpen, onClose, totalAmount }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Payment Modal"
      style={customModalStyle}
    >
      <MDBModalBody>
        <Payment totalAmount={totalAmount} />
        <button onClick={onClose}>Close</button>
      </MDBModalBody>
    </Modal>
  );
};

export default CheckoutModal;
