import React, { useState } from 'react';
import NavBar from '../components/NavBar/NavBar';
import Footer from '../components/Footer/Footer';
import { PRODUCTS } from '../product';
import EcomHero from '../components/Ecom/Hero';
import CatBanner from '../components/Ecom/CatBanner';
import { MDBContainer, MDBTypography } from 'mdb-react-ui-kit';
import EcomDisplay from './singlepage/EcomDisplay';
import { Fade } from "react-awesome-reveal";



const Ecom = () => {
  const [products, setProducts] = useState(PRODUCTS); // Add state for products

  return (
    <div>
      <NavBar />
      <EcomHero />
      <CatBanner />
      <MDBContainer fluid className='px-5'>
        <Fade>
          <MDBTypography variant='display-2' className='text-center fw-bold p-5 m-5'>
            APPARELS
          </MDBTypography>
        </Fade>
        <EcomDisplay />
      </MDBContainer>

      <Footer />
    </div>
  )
}

export default Ecom;
