import React from "react";
import { MDBBtn, MDBCard, MDBCardBody, MDBCol, MDBContainer, MDBRadio, MDBRow } from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCcVisa } from "@fortawesome/free-brands-svg-icons";
import toast, { Toaster } from 'react-hot-toast';
import { useContext } from "react";
import { ShopContext } from "../Ecom/EcomContext";

const Payment = ({ totalAmount }) => {

  const { checkout } = useContext(ShopContext);

  const handlePayment = () => {
    // Perform the payment logic here, if needed.
    // Assuming it's successful, show the success toast.
    toast.success('Payment Successful!');

    setTimeout(() => {
      checkout();  // Clear the cart after a delay
    }, 3000);  // 3000 milliseconds = 3 seconds
  };



  return (
    <MDBContainer fluid className="py-5" style={{ backgroundColor: "#eee" }}>
      <MDBRow className="d-flex justify-content-center">
        <MDBCol md="8" lg="6" xl="4">
          <MDBCard className="rounded-3">
            <MDBCardBody className="mx-1 my-2">
              <div className="d-flex align-items-center">
                <div>
                  {/* Use the FontAwesomeIcon component */}
                  <FontAwesomeIcon icon={faCcVisa} size="4x" className="text-black pe-3" />
                </div>
                <div>
                  <p className="d-flex flex-column mb-0">
                    <b>Dear Costumer</b>
                    <span className="small text-muted">**** 8880</span>
                  </p>
                </div>
              </div>
              <div className="pt-3">
                <div className="d-flex flex-row pb-3">
                  <div
                    className="rounded border border-primary border-2 d-flex w-100 p-3 align-items-center"
                    style={{ backgroundColor: "rgba(18, 101, 241, 0.07)" }}
                  >
                    <div className="d-flex align-items-center pe-3">
                      <MDBRadio name="radioNoLabelX" id="radioNoLabel11" defaultChecked />
                    </div>
                    <div className="d-flex flex-column">
                      <p className="mb-1 small text-primary">Total amount due</p>
                      <h6 className="mb-0 text-primary">${totalAmount}</h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center pb-1">
                <Link to="/Ecom" className="text-muted">
                  Go back
                </Link>
                <MDBBtn onClick={handlePayment} size="lg" className="custom-button float-end">Pay amount</MDBBtn>
                <Toaster
                  position="top-center"
                  reverseOrder={false}
                />
              </div>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default Payment;
