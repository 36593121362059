import React from 'react';
import {
  MDBFooter,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBTypography,
  MDBBtn
} from 'mdb-react-ui-kit';
import Logo from '../../img/logo.jpg';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <MDBFooter className='text-lg-left bg-pink' style={{ width: '100%', marginTop: 'auto' }} id='footer'>
      <MDBContainer className='p-4 pb-0'>
        <MDBRow className='mt-3'>
          <MDBCol md='3' lg='4' xl='3' className='mx-auto mb-4'>
            <MDBRow>
              <MDBCol md='3' lg='4' xl='3'>
                <img src={Logo} alt='' style={{ width: '50px', height: '50px', objectFit: 'cover', borderRadius: '5px' }} />
              </MDBCol>
              <MDBCol md='3' lg='4' xl='3'>
                <MDBTypography tag='h1'>
                  printState
                </MDBTypography>
              </MDBCol>
            </MDBRow>
            <MDBTypography tag='p'>
              printState is an innovative e-commerce platform where creativity meets commerce. Design and customize a wide range of products, from fashion items to accessories. Publish your creations and earn a profit. Join printState today!
            </MDBTypography>
          </MDBCol>

          <MDBCol md='3' lg='2' xl='2' className='mx-auto mb-4'>
            <h6 className='text-uppercase fw-bold mb-4'>Useful links</h6>
            <p>
              <Link to='/' className='text-reset'>
                How it Works?
              </Link>
            </p>
            <p>
              <Link to='/' className='text-reset'>
                Custom
              </Link>
            </p>
            <p>
              <Link to='/Ecom' className='text-reset'>
                Shop
              </Link>
            </p>
            <p>
              <Link to='/' className='text-reset'>
                Need Help?
              </Link>
            </p>
          </MDBCol>

          <MDBCol md='4' lg='3' xl='3' className='mx-auto mb-md-0 mb-4'>
            <h6 className='text-uppercase fw-bold mb-4'>Contact Us</h6>
            <p>
              <MDBIcon color='secondary' icon='home' className='text-white' /> Manila, Philippines
            </p>
            <p>
              <MDBIcon color='secondary' icon='envelope' className='text-white' /> printstate@gmail.com
            </p>
            <p>
              <MDBIcon color='secondary' icon='phone' className='text-white' /> +81-80-9039-1994
            </p>
            <Link to='https://www.facebook.com/aadasalla/' target='_blank'>
              <MDBBtn outline color="light" floating className='m-1' role='button'>
                <MDBIcon fab icon='facebook-f' />
              </MDBBtn>
            </Link>


            <Link to='https://www.linkedin.com/in/aveunalliv-dasalla-91523a277/' target='_blank'>
              <MDBBtn outline color="light" floating className='m-1' role='button'>
                <MDBIcon fab icon='linkedin-in' />
              </MDBBtn>
            </Link>


            <Link to='https://github.com/maloya02' target='_blank'>
              <MDBBtn outline color="light" floating className='m-1' role='button'>
                <MDBIcon fab icon='github' />
              </MDBBtn>
            </Link>

          </MDBCol>
        </MDBRow>
      </MDBContainer>

      <div className='text-center p-3 text-pink' style={{ backgroundColor: '#0C0C0C' }}>
        &copy; {new Date().getFullYear()}{' '}
        <Link className='text-light' to='https://github.com/maloya02' target='_blank'>
          All Rights Reserved
        </Link>
      </div>
    </MDBFooter>
  );
}

export default Footer;
